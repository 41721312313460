import { Select as MuiSelect } from '@mui/material'

export default function Select(props) {
  return <MuiSelect
    autoWidth
    MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null,
      PaperProps: {
        style: { minWidth: '300px' },
      }
    }}
    {...props}
  />
}
