import React from 'react'
import {
  TextField,
  InputAdornment,
  Slider,
  Box
} from '@mui/material'

export default function SystemSize(props){
  const {
    handleSystemSizeKW,
    systemSizeKW,
    contractSignDate,
    setContractSignDate
  } = props

  return(
    <div className='flex-column width45 flex-jc-sb '>
      <p>System size (kW)</p>
      <div className='flex-row width100 flex-ai-fs'>
        {/* <Box width={'100%'}>
          <Slider
            onChange={e => {handleSystemSizeKW(e.target.value);}}
            step={0.001}
            defaultValue={0}
            min={1.80}
            max={100}
            aria-label="Default"
            valueLabelDisplay="off" />
        </Box> */}

        <TextField
          id="outlined-number-system-size"
          type="number"
          onChange={e => handleSystemSizeKW(e.target.value)}
          value={systemSizeKW}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 0.001,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end"><p className="color-white">kW</p></InputAdornment>
          }}
          sx={{width: '100%'}}
        />
      </div>
      {/* <Box width={'100%'} style={{textAlign: "center"}}>
        <p style={{marginTop: "-2px"}}>{systemSizeKW ? systemSizeKW : 0} / kW</p>
      </Box>       */}
    </div>
  )
}