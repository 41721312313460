import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'

export default function LeaderShipLevel(props) {
  const {
    leadershipLevel,
    handleLeadershipLevel,
    repTenure,
    fetchLeadershipOptions,
    contractSignDate
  } = props

  const [newLeadershipLevels, setNewLeadershipLevels] = useState([]);

  useEffect(() => {
    if(leadershipLevel.length == 0) {
      fetchLeadershipOptions(contractSignDate)
    }
  }, [])

  useEffect(() => {
    const newLevels = leadershipLevel.filter(l => !l.isHidden)
    setNewLeadershipLevels(newLevels);
  }, [leadershipLevel])

  const buildLeadershipLevelArray = (title, leadershipLevel) => {
    let selectedTitles = leadershipLevel.filter(x => x.selected).map(x => x.title)
    let deselecting = selectedTitles.includes(title)
    let currentAndLast = selectedTitles.length === 1 && selectedTitles.includes(title)
    let array = leadershipLevel

    array.forEach((x, index) => {
      if(x.title == title && !deselecting) {
        x.selected = true
      } else if (x.title == title && deselecting && (repTenure.value || !currentAndLast)) {
        x.selected = false
      }
    })

    handleLeadershipLevel(state => {
      return [...state]
    })
  }

  return(
    <div className='width100 flex-column flex-ai-fs flex-jc-fs'>
      <div className='flex-column width100'>
        {newLeadershipLevels?.length > 0 ? <p>Leadership Level.<span className='select-one'>(select one)</span></p> : null }
          <div className='width100 flex-row-wrap flex-jc-sb'>
            {newLeadershipLevels?.map((option,i) => {
              return(
                <Button
                  sx={{fontSize: '0.75rem', width: '140px'}} 
                  key={option.title + option.value + i}
                  variant={option.selected ? "contained" : "outlined"}
                  onClick={() => buildLeadershipLevelArray(option.title, newLeadershipLevels)}
                > 
                  {option.title}
                </Button>
              )
            })}
          </div>
      </div>
    </div>
  )
}