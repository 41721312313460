import React from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '../Select';
import Box from '@mui/material/Box';
import SystemAttributes from './SystemAttributes';
import PayScale from './PayScale';
import Button from '@mui/material/Button';
import HeartImage from '../../assets/Heart Icon.svg';
import CommissionBreakout from './CommissionBreakout';
import BaselineModal from '../Modals/BaselineModal';
// import YearlyCommissionEstimate from './YearlyCommissionEstimate';
// import TextField from '@mui/material/TextField'

function LoanAndCash(props) {
  const {
    installer,
    GSFormValues,
    handleInstaller,
    netPricePerWatt,
    handleNetPricePerWatt,
    systemSizeKW,
    handleSystemSizeKW,
    handleDeductions,
    handleLead,
    lead,
    deductions,
    market,
    repTenureValues,
    repTenure,
    product,
    installDate,
    installerBaseline,
    netPricePerWattValues,
    tcrVariables,
    paymentCalculationsToDisplay,
    buildTablePaymentCalculations,
    estimatedInstalls,
    handleEstimatedInstalls,
    selectedPayScale,
    setContractSignDate,
    contractSignDate,
    handlePayscaleChange,
    payscale,
    repTenurePercentages,
    jrecMinimumCommissionPerKW,
    apptFee,
    // handleApptFee,
    // handleConciergeFee,
    conciergeFee,
    decimals,
  } = props;

  return (
    <>
      <div className="width100 flex-column flex-jc-fs">
        <SystemAttributes
          netPricePerWatt={netPricePerWatt}
          handleNetPricePerWatt={handleNetPricePerWatt}
          systemSizeKW={systemSizeKW}
          handleSystemSizeKW={handleSystemSizeKW}
          handleLead={handleLead}
          handleDeductions={handleDeductions}
          lead={lead}
          deductions={deductions}
          installer={installer}
          handleInstaller={handleInstaller}
          GSFormValues={GSFormValues}
          market={market}
          repTenureValues={repTenureValues}
          product={product}
          installDate={installDate}
          installerBaseline={installerBaseline}
          netPricePerWattValues={netPricePerWattValues}
          tcrVariables={tcrVariables}
          setContractSignDate={setContractSignDate}
          contractSignDate={contractSignDate}
          repTenure={repTenure}
          payscale={payscale}
          handlePayscaleChange={handlePayscaleChange}
        />
      </div>

      {/* <div className='width100' style={{display: "flex", justifyContent: "center", marginTop: "15px", marginBottom: "10px"}}>
        <div className='width50 freedom-lead'>
          <p>Freedom Lead?<span className='select-one'>(select one)</span></p>
          <div className='width100 pp-flex-row'>
            <Button className='' variant={apptFee == 'vivint' ? "contained" : "outlined"}  onClick={() => handleApptFee('vivint')} sx={{marginRight: "8px"}}>Yes</Button>
            {/* <Button className='' variant={apptFee == 'nonVivint' ? "contained" : "outlined"} onClick={() => handleApptFee('nonVivint')}>No Vivint</Button> */}
      {/* <Button className='' variant={apptFee == 'noAppt' ? "contained" : "outlined"} onClick={() => handleApptFee('noAppt')}>No</Button>
          </div>
        </div>
      </div> */}
      <div className="width100 calculate-pay" style={{ textAlign: 'center' }}>
        <Button
          sx={{
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': {
              bgcolor: '#a0f01c',
              color: '#888888',
            },
            my: 3,
          }}
          variant="contained"
          onClick={() =>
            buildTablePaymentCalculations(
              {
                market,
                repTenurePercentages,
                product,
                systemSizeKW,
                netPricePerWatt,
                lead,
                deductions,
                contractSignDate,
                installerBaseline,
                payscale,
                jrecMinimumCommissionPerKW,
                repTenure,
                apptFee,
                conciergeFee,
              },
              netPricePerWattValues,
              tcrVariables,
              systemSizeKW
            ).then(() => {
              document.querySelector('#payscale-table').scrollIntoView();
              document.querySelector('#submit-payscale').style.backgroundColor = '#a0f01c';
              document.querySelector('#submit-payscale').style.color = 'black';
            })
          }
          id="submit-payscale"
          className="width45 margin-top"
          size="large"
        >
          Calculate My Pay
        </Button>
      </div>
      <BaselineModal />
      <Box textAlign="center" width={1} mt={2}>
        <div className="width100 calculate-pay" style={{ textAlign: 'center' }}>
          <Button
            sx={{
              textTransform: 'none',
              width: '100%',
              color: '#f9f9f9',
              border: 'solid 2px #E0DED4',
              bgColor: '#111111',
            }}
            variant="contained"
            onClick={() => window.open('/commfaq.pdf')}
            className="width45"
            size="large"
          >
            FAQs
          </Button>
        </div>
      </Box>

      <div id="payscale-table" className="width100">
        <PayScale
          paymentCalculationsToDisplay={paymentCalculationsToDisplay}
          estimatedInstalls={estimatedInstalls}
          handleEstimatedInstalls={handleEstimatedInstalls}
          selectedPayScale={selectedPayScale}
          decimals={decimals}
        />
      </div>

      <div className="width100">
        <CommissionBreakout
          paymentCalculationsToDisplay={paymentCalculationsToDisplay}
          selectedPayScale={selectedPayScale}
          systemSizeKW={systemSizeKW}
          repTenure={repTenure}
          decimals={decimals}
        />
      </div>

      <div className="width100" style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
        <p className="manufacture-by" style={{ color: '#888888' }}>
          Made with
        </p>
        <img src={HeartImage} alt="heartimage" style={{ width: '20px', marginLeft: '10px', marginRight: '8px' }} />
        <p className="manufacture-by" style={{ color: '#888888' }}>
          by the VITL Creative & Dev Team
        </p>
      </div>
    </>
  );
}

export default LoanAndCash;
