import React from 'react';
import Slider from '@mui/material/Slider';
import AdditionalSettings from './AdditionalSettings';
import Box from '@mui/material/Box';
import SystemSize from '../SystemSize';
import { TextField, InputAdornment } from '@mui/material';

export default function SystemAttributes(props) {
  const {
    netPricePerWatt,
    handleNetPricePerWatt,
    systemSizeKW,
    handleSystemSizeKW,
    handleDeductions,
    handleLead,
    lead,
    deductions,
    installer,
    market,
    product,
    installerBaseline,
    netPricePerWattValues,
    tcrVariables,
    contractSignDate,
    setContractSignDate,
    repTenure,
    payscale,
    handlePayscaleChange,
  } = props;

  return (
    <div className="width100 flex-column flex-ai-fs flex-jc-fs" style={{ marginTop: '20px' }}>
      <div className="flex-row flex-jc-sb width100">
        <div className="flex-column width45 flex-jc-sb">
          <div className="flex-row width100 flex-jc-sb flex-ai-center">
            <p>Net Price per Watt</p>
            {/* <p className="color-white border-white padding5 margin-right0">${netPricePerWatt.toFixed(2)}</p> */}
          </div>
          <div className="flex-row width100 flex-ai-fs">
            {/* <Box width={'100%'}>
              <Slider
                onChange={e => {handleNetPricePerWatt(e.target.value);}}
                step={0.01}
                defaultValue={netPricePerWatt}
                min={1.80}
                max={5.75}
                aria-label="Default"
                valueLabelDisplay="off" />
            </Box> */}
            <TextField
              id="outlined-number"
              type="number"
              defaultValue={netPricePerWatt}
              onChange={(e) => handleNetPricePerWatt(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 0.01,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <p className="color-white">/ watt</p>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '100%' }}
            />
          </div>
          {/* <Box width={'100%'} style={{textAlign: "center"}}>
            <p style={{marginTop: "-2px"}}>{netPricePerWatt.toFixed(2)} / watt</p>
          </Box> */}
        </div>

        <SystemSize
          handleSystemSizeKW={handleSystemSizeKW}
          market={market}
          installer={installer}
          product={product}
          systemSizeKW={systemSizeKW}
          netPricePerWatt={netPricePerWatt}
          lead={lead}
          deductions={deductions}
          contractSignDate={contractSignDate}
          setContractSignDate={setContractSignDate}
          installerBaseline={installerBaseline}
          netPricePerWattValues={netPricePerWattValues}
          tcrVariables={tcrVariables}
        />
      </div>

      {/* <AdditionalSettings
        handleLead={handleLead}
        handleDeductions={handleDeductions}
        lead={lead}
        deductions={deductions}
        repTenure={repTenure}
        onTrip={onTrip}
        handleOnTrip={handleOnTrip}
      /> */}
    </div>
  );
}
