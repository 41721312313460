import './App.css';
import { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import LoanAndCash from './components/LoanAndCash/LoanAndCash';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from './components/Select';
import Box from '@mui/material/Box';
import AuthenticationModal from './components/AuthenticationModal';
import LeaderShipLevel from './components/LeaderShipLevel';
import RepTenure from './components/RepTenure';
import { useCookies } from 'react-cookie';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import WebFont from 'webfontloader';
import LogoImage from './assets/logo.png';
import ScrollImage from './assets/Scroll Icon.svg';
import { uniq, uniqBy } from 'lodash';
import { round } from 'mathjs';

function App() {
  const [market, setMarket] = useState('');
  const [payscale, setPayscale] = useState('');
  const [installer, setInstaller] = useState('');
  const [installerValues, setInstallerValues] = useState([]);
  const [contractSignDate, setContractSignDate] = useState(new Date());
  const [installerBaseline, setInstallerBaseline] = useState(0);
  const [paymentCalculationsToDisplay, setPaymentCalculationsToDisplay] = useState([]);
  const [paymentCalculations, setPaymentCalculations] = useState([]);
  const [repTenureValues, setRepTenureValues] = useState([]);
  const [repTenure, setRepTenure] = useState({ value: 0, id: 0 });
  const [product, setProduct] = useState('');
  const [GSFormValues, setGSFormValues] = useState();
  const [netPricePerWatt, setNetPricePerWatt] = useState(3.65);
  const [systemSizeKW, setSystemSizeKW] = useState(0);
  const [lead, setLead] = useState(false);
  const [apptFee, setApptFee] = useState('noAppt');
  const [conciergeFee, setConciergeFee] = useState(false);
  const [deductions, setDeductions] = useState(0);
  const [netPricePerWattValues, setNetPricePerWattValues] = useState([]);
  const [tcrVariables, setTcrVariables] = useState({});
  const [estimatedInstalls, setEstimatedInstalls] = useState(1);
  const [selectedPayScale, setSelectedPayScale] = useState({
    totalCommission: 0,
  });
  const [utilityCompany, setUtilityCompany] = useState('');
  const [EPCValues, setEPCValues] = useState([]);
  const [sunRunMarketValues, setSunRunMarketValues] = useState([]);
  const [loanAndCashMarketValues, setLoanAndCashMarketValues] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [leadershipLevel, setLeadershipLevel] = useState([]);
  const [calculationsSubmitted, setCalculationsSubmitted] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['authenticate', 'userType', 'dealer']);
  const [jrecMinimumCommissionPerKW, setJRECMinimumCommissionPerKW] = useState(0);
  const [repTenurePercentages, setRepTenurePercentages] = useState(0);
  const [uncommon, setUncommon] = useState(false);
  const [uncommonDisabled, setUncommonDisabled] = useState(false);
  const [decimals, setDecimals] = useState(2);

  const calculatePayment = async (market, installer, product, contractSignDate) => {
    await setPaymentCalculations([]);

    let res = await fetch(`/api/calculate_payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInputs: {
          installer: installer,
          market: market,
          product: product,
          contractSignDate: contractSignDate,
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        setInstallerBaseline(json.A);
        setTcrVariables(json.tcrVariables);

        return { installerBaseline: json.A, tcrVariables: json.tcrVariables };
      });

    return res;
  };

  const buildTablePaymentCalculations = async () => {
    const userInputs = {
      market,
      repTenure,
      repTenurePercentages,
      product,
      systemSizeKW,
      netPricePerWatt,
      lead,
      deductions,
      contractSignDate,
      installerBaseline,
      payscale,
      jrecMinimumCommissionPerKW,
      apptFee,
      conciergeFee,
      utilityCompany,
    };
    console.log('***************', userInputs);
    let variables = await getTcrVariables({ installer: userInputs.installer });
    let A = Number(getBaseline(userInputs.market, userInputs.product));
    let C = Number(userInputs.systemSizeKW);
    let E = Number(variables.tcrConstant);
    let F = Number(variables.tcrAdjuster);

    let calculations = await netPricePerWattValues.map((B) => {
      let paymentCalculation = crunchNumbers(A, B, C, E, F, userInputs);

      return paymentCalculation;
    });
    let selection = calculations.filter((c) => Number(c.nppw) == Number(userInputs.netPricePerWatt))[0];

    setPaymentCalculations(calculations);
    grabTenTotalCommissionValues(calculations, userInputs.netPricePerWatt);
    setSelectedPayScale(selection);
    setCalculationsSubmitted(true);

    return calculations;
  };

  const getTcrVariables = (userInputs) => {
    console.log('************getting tcr values');
    return fetch(`/api/get_variables`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInputs: {
          installer: installer,
          market: market,
          product: product,
          contractSignDate: contractSignDate,
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        setTcrVariables(json);

        return json;
      });
  };

  const crunchNumbers = (A, B, C, E, F, userInputs) => {
    const { payscale, jrecMinimumCommissionPerKW, repTenure, market, apptFee, conciergeFee, utilityCompany } = userInputs;
    console.log('>>>>', { payscale, repTenure, apptFee });
    console.log('--------------- New Calculation -------------');
    let sumOfLeadership = sumOfSelectedLeadershipValues(payscale);
    console.log('repTenure percentages', repTenure.value);
    console.log('sum of leadership values', sumOfLeadership);
    // let percentagesTotal = Number(repTenure.value) + Number(sumOfLeadership);
    // console.log("sum of leadership + repTenure percentages", percentagesTotal);
    const totalFees = addAllFees(market, apptFee, conciergeFee, Number(C));
    console.log('totalFees', totalFees);
    let finalNppw = Number(B) - totalFees;

    const prefix = (finalNppw - Number(A)) * Number(C) * 1000 * Number(E) - Number(F);
    let leadTypeSum = prefix * +repTenure.value;
    if (payscale === 'Retail') {
      leadTypeSum = prefix * 0.6;
    }
    const leaderSum = payscale === 'On Trip' ? +sumOfLeadership * 1000 * Number(C) : prefix * +sumOfLeadership;

    let paymentCalculation = leadTypeSum + leaderSum;
    if (payscale === 'Retail' && repTenure.id === 'Self gen') {
      paymentCalculation += 100 * Number(C);
    }

    console.log('B', Number(B));
    console.log('finalNppw after additional fees', finalNppw);
    console.log('A', Number(A));
    console.log('siteAuditFee', Number(market.siteAuditFee));
    console.log('C', Number(C));
    console.log('E', Number(E));
    console.log('F', Number(F));
    console.log('paymentCalculation', paymentCalculation);

    let commissionPerKW = Number(paymentCalculation / C);
    console.log('commissionPerKW', commissionPerKW);
    console.log('jrecMinimumCommissionPerKW', jrecMinimumCommissionPerKW);

    console.log('repTenure', repTenure);
    if (Number(commissionPerKW) < Number(jrecMinimumCommissionPerKW) && repTenure.id === 'Set') {
      commissionPerKW = jrecMinimumCommissionPerKW;
      paymentCalculation = commissionPerKW * C;
    }

    return {
      totalCommission: paymentCalculation,
      nppw: B,
      commissionPerKW: commissionPerKW,
    };
  };

  const addAllFees = (market, apptFee, conciergeFee, systemSizeKW) => {
    let apptFeeValue = 0;
    let conciergeFeeValue = 0;
    apptFeeValue = round(Number(market.apptFee / 0.8), 4);
    if (apptFee == 'vivint') {
      apptFeeValue += Number(market.vivApptFee / 0.8);
    }

    const auditFee = round(market.siteAuditFee / 0.8 / (systemSizeKW * 1000), 6);
    console.log('$&#*&$*#&FEES', {
      apptFeeValue,
      conciergeFeeValue,
      auditFee,
    });
    const totalFee = apptFeeValue + conciergeFeeValue + auditFee;
    return totalFee;
  };

  const sumOfSelectedLeadershipValues = (payscale) => {
    const newLeadershipLevels = leadershipLevel.filter((l) => !l.isHidden);
    if (newLeadershipLevels.length == 0) {
      return 0;
    } else {
      let selectedValues = newLeadershipLevels.map((x) => {
        if (x.selected) {
          if (x.xFlatOverride || x.yOnTripValue) {
            if (payscale === 'On Trip') {
              return Number(x.xFlatOverride);
            } else if (payscale === 'Off Trip/Local') {
              return Number(x.yOnTripValue);
            } else if (payscale === 'Retail') {
              return Number(x.yOnTripValue);
            }
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });

      const initialValue = 0;
      let sum = selectedValues.reduce((pV, cV) => pV + cV, initialValue);

      if (uncommon && newLeadershipLevels.length > 0) {
        // Leadership Login:
        // Self Gen + Uncommon + DM Selected (add any others too like ADM or Recruiter if they have selected it) = Normal Self Gen Pay + Half of RM + Half of Sr RM
        const halfRM = leadershipLevel.find((level) => level.title === 'RM')?.value / 2 || 0;
        const halfSrRM = leadershipLevel.find((level) => level.title === 'Sr RM')?.value / 2 || 0;
        sum = sum + halfRM + halfSrRM;
      }

      if (uncommon && newLeadershipLevels.length === 0) {
        // Non Leadership Login:
        // Self Gen + Uncommon Selected = Normal Self Gen Pay + Half of DM + Half of RM
        const halfSrDM = leadershipLevel.find((level) => level.title === 'RM')?.value / 2 || 0;
        const halfDM = leadershipLevel.find((level) => level.title === 'DM')?.value / 2 || 0;
        sum = sum + halfSrDM + halfDM;
      }

      return sum;
    }
  };

  const grabTenTotalCommissionValues = async (paymentCalculations, nppw) => {
    await setPaymentCalculationsToDisplay([]);

    const activeRowIndex = paymentCalculations.findIndex((pc) => {
      return parseFloat(pc.nppw) === Number(nppw);
    });

    let visibleRows = [];

    if (activeRowIndex > 5) {
      visibleRows = paymentCalculations.slice(activeRowIndex - 5, activeRowIndex + 6);
    } else {
      visibleRows = paymentCalculations.slice(0, 11);
    }

    setPaymentCalculationsToDisplay(visibleRows);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#333',
      },
      secondary: {
        main: blue[100],
      },
    },
  });

  const handleInstaller = async (installer) => {
    await setInstaller(installer);
    if (installer && market) {
      let res = await calculatePayment(market, installer, product, contractSignDate);
    }
  };

  const fetchLeadershipOptions = (contractSignDate) => {
    if (cookies.userType && cookies.dealer) {
      return fetch(`/api/leadership-levels/${cookies.dealer}/${cookies.userType}/${contractSignDate}`)
        .then((res) => res.json())
        .then((json) => {
          let build = json.map((x) => {
            return { ...x, selected: false };
          });
          return handleLeadershipLevel(build);
        });
    } else {
      return;
    }
  };

  const fetchRepTenureVariables = (contractSignDate) => {
    return fetch(`/api/rep-tenure/variables/${cookies.dealer}/${cookies.userType}/${contractSignDate}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setRepTenureValues(json);
        return json;
      });
  };

  const autoSetMarket = async (payscale, loanAndCashMarketValues) => {
    let newMarket = {};

    if (market) {
      newMarket = loanAndCashMarketValues
        ?.filter((x) => {
          if (payscale === 'On Trip') {
            return x.bucket === 'X';
          } else if (payscale === 'Off Trip/Local') {
            return x.bucket === 'Y';
          } else if (payscale === 'Retail') {
            return x.bucket === 'Retail';
          }
        })
        .filter((x) => x.market == market.market)[0];
    } else {
      return;
    }

    await handleMarket(newMarket, EPCValues, product, GSFormValues.marketValues, payscale);

    return newMarket;
  };

  const handlePayscaleChange = (payscale) => {
    setPayscale(payscale);

    const market = autoSetMarket(payscale, loanAndCashMarketValues);
    const repTen = repTenureValues.filter((x) => x.title === repTenure.id)[0];

    handleRepTenure(repTen, payscale, market);
  };

  const handleContractSignDate = async (date) => {
    setContractSignDate(new Date(date));

    const res = await getFormData(new Date(date));
    const newRepTenureValues = await fetchRepTenureVariables(new Date(date));
    const newLeadershipLevelValues = await fetchLeadershipOptions(new Date(date));

    await reselectRepTenureAndLeadershipValues(repTenure, newRepTenureValues, leadershipLevel, newLeadershipLevelValues);
    await autoSetMarket(payscale, res);

    if (installer && market) {
      let res = await calculatePayment(market, installer, product, contractSignDate);
    }
  };

  const handleLeadershipLevel = (leadershipLevel) => {
    setLeadershipLevel(leadershipLevel);

    return leadershipLevel;
  };

  const getBaseline = (market, product) => {
    if (product === 'Loan and Cash') {
      return market.baseline;
    } else if (product === 'Sunrun TPO') {
      return market.ppaBaseline;
    } else if (product === 'Enfin TPO') {
      return market.enfinTpoBaseline;
    } else if (product === 'Everbright TPO') {
      return market.everbrightTpoBaseline;
    } else if (product === 'Sunnova TPO') {
      return market.sunnovaTpoBaseline;
    } else if (product === 'Goodleap TPO') {
      return market.goodleapTpoBaseline;
    }
  };

  const handleMarket = async (market, EPCValues, product, marketValues, _) => {
    let jrecMinimumCommissionPerKW = market.minimumCommissionPerKW;

    await setMarket(market);
    await setJRECMinimumCommissionPerKW(jrecMinimumCommissionPerKW);

    if (!market.ppaBaseline) {
      await setProduct('');
      product = '';
    }
  };

  const buildNetPricePerWattValues = (netPricePerWatt) => {
    let start_nppw = Number(netPricePerWatt);
    let decimals = `${netPricePerWatt}`.split('.').pop().length;

    if (decimals < 2) {
      decimals = 2;
    }
    let netPricePerWattValues = [];

    for (let i = start_nppw - 1; i <= start_nppw + 1; i += 0.05) {
      netPricePerWattValues.push(+i.toFixed(decimals));
    }
    setDecimals(decimals);
    setNetPricePerWattValues(netPricePerWattValues);

    return netPricePerWattValues;
  };

  const handleNetPricePerWatt = async (netPricePerWatt) => {
    buildNetPricePerWattValues(netPricePerWatt);

    setNetPricePerWatt(netPricePerWatt);
  };

  const handleApptFee = async (apptFee) => {
    setApptFee(apptFee);
  };

  const handleConciergeFee = async (conciergeFee) => {
    setConciergeFee(conciergeFee);
  };

  const handleSystemSizeKW = (systemSizeKW) => {
    setSystemSizeKW(systemSizeKW);
  };

  const handleLead = (lead, repTenure) => {
    let repTenureValuePercentages = 0;

    if (lead === '50/50') {
      repTenureValues.forEach((x) => {
        if (payscale === 'On Trip') {
          repTenureValuePercentages += Number(x.value);
        } else if (payscale === 'Off Trip/Local') {
          repTenureValuePercentages += Number(x.yOnTripValue);
        } else if (payscale === 'Retail') {
          repTenureValuePercentages += Number(x.yOnTripValue);
        }
      });

      repTenureValuePercentages = repTenureValuePercentages / 2;
    } else if (lead === 'selfGen') {
      repTenureValues.forEach((x) => {
        if (payscale === 'On Trip') {
          repTenureValuePercentages += Number(x.value);
        } else if (payscale === 'Off Trip/Local') {
          repTenureValuePercentages += Number(x.yOnTripValue);
        } else if (payscale === 'Retail') {
          repTenureValuePercentages += Number(x.yOnTripValue);
        }
      });
    } else if (lead === 'leadSet' || lead === 'leadClosed') {
      if (payscale === 'On Trip') {
        repTenureValuePercentages = Number(repTenure.value);
      } else if (payscale === 'Off Trip/Local') {
        repTenureValuePercentages = Number(repTenure.yOnTripValue);
      } else if (payscale === 'Retail') {
        repTenureValuePercentages += Number(repTenure.yOnTripValue);
      }
    }
    let repTenurePercentages = { value: repTenureValuePercentages };

    setLead(lead);
    setRepTenurePercentages(repTenurePercentages);
  };

  const handleDeductions = (deductions) => {
    setDeductions(deductions);
  };

  const handleEstimatedInstalls = (estimatedInstalls) => {
    setEstimatedInstalls(estimatedInstalls);
  };

  const reselectRepTenureAndLeadershipValues = async (oldRepTenure, newRepTenureValues, oldLeadershipLevel, newLeadershipLevelValues) => {
    const selectedRepTen = newRepTenureValues.filter((x) => x.title === oldRepTenure.id)[0];

    let value = selectedRepTen.value;
    let id = selectedRepTen.title;

    if (payscale !== 'On Trip') {
      value = selectedRepTen.yOnTripValue;
    }

    const repTen = { id: id, value };
    const build = newLeadershipLevelValues.map((x) => {
      let psll = oldLeadershipLevel.filter((o) => o.title === x.title)[0];
      return { ...x, selected: psll.selected };
    });

    setRepTenure(repTen);
    setLeadershipLevel(build);
  };

  const handleRepTenure = async (repTen, newPayscaleValue, market) => {
    let value = repTen.value;
    let id = repTen.title;

    if (newPayscaleValue !== 'On Trip') {
      value = repTen.yOnTripValue;
    }

    let leadershipLevelSelected = leadershipLevel.filter((x) => x.selected).length > 0;
    console.log('handleRepTenure', { newPayscaleValue, payscale, leadershipLevelSelected, repTen, repTenure });
    if (repTenure.id != id) {
      const repTenure = { value, id: id };
      setRepTenure(repTenure);
      const repTenurePercentages = await handleLead('50/50', repTenure, market, newPayscaleValue);
    } else if (repTenure.id == id && leadershipLevelSelected) {
      const repTenure = { value: '', id: '' };
      let lead = '';
      let repTenurePercentages = { value: 0 };

      setRepTenure(repTenure);
      setLead(lead);
      setRepTenurePercentages(repTenurePercentages);
    }
  };

  const handleRepTenureValues = (repTenureValues) => {
    setRepTenureValues(repTenureValues);
  };

  const handleProduct = (product) => {
    setProduct(product);
  };

  const getFormData = (contractSignDate) => {
    console.log('formData method hit');
    if (cookies.authenticate) {
      console.log('getting Form Data');
      const res = fetch(`/api/form_values/${product == 1}/${contractSignDate}`)
        .then((res) => res.json())
        .then((json) => {
          setGSFormValues(json);
          setLoanAndCashMarketValues(json);

          return json;
        });
      return res;
    } else {
      return;
    }
  };

  const authenticate = (password) => {
    fetch(`/api/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
      .then((res) => {
        if (res.status == 200) {
          setAuthenticated(true);

          let expireDate = new Date();
          expireDate.setDate(expireDate.getDate() + 7);
          setCookie('authenticate', true, { path: '/', expires: expireDate });
        } else {
          setErrorMessage('Wrong Password');
        }
        console.log('res from auth', res);
        return res.json();
      })
      .then((json) => {
        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 7);

        setCookie('passwordUsed', json.passwordUsed, {
          path: '/',
          expires: expireDate,
        });
        return json;
      })
      .then((json) => {
        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 7);

        setCookie('userType', json.userType, {
          path: '/',
          expires: expireDate,
        });
        setCookie('dealer', json.dealer, { path: '/', expires: expireDate });

        return json.userType;
      })
      .then((userType) => {
        console.log('auth: ', cookies.authenticate);

        window.location.reload();
        getFormData(contractSignDate);
      })

      .catch((error) => error);
  };

  const logout = () => {
    setAuthenticated(false);
    setErrorMessage('');
    setLeadershipLevel([]);
    removeCookie('authenticate');
    removeCookie('userType');
    removeCookie('dealer');
  };

  const onChangeUncommon = (e) => {
    setUncommon(e.target.checked);
    if (e.target.checked) {
      const selfGenValue = {
        id: 'Self gen',
        value: '0.6',
      };
      setRepTenure(selfGenValue);
      const newLeadershipLevels = leadershipLevel.map((lead) => ({
        ...lead,
        selected: lead.title === 'DM' ? true : false,
      }));
      setLeadershipLevel(newLeadershipLevels);
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Source Code Pro'],
      },
    });

    buildNetPricePerWattValues(netPricePerWatt);
    setAuthenticated(cookies.authenticate);
    getFormData(contractSignDate);
  }, []);

  useEffect(() => {
    if (calculationsSubmitted) {
      buildTablePaymentCalculations();
    }

    const selected = leadershipLevel.filter((leadership) => leadership.selected).map((l) => l.title);
    if (selected.includes('RM') || selected.includes('Sr RM') || selected.includes('VP')) {
      setUncommonDisabled(true);
      setUncommon(false);
    } else {
      setUncommonDisabled(false);
    }
  }, [market, repTenure, product, netPricePerWatt, systemSizeKW, lead, deductions, apptFee, leadershipLevel, uncommon]);

  const utilityCompaniesForEnfinTpo = useMemo(() => {
    if (!GSFormValues || !market) return [];
    return uniq(GSFormValues.filter((row) => !!row.utilityCompanyForEnfinTpo && row.market === market.market).map((row) => row.utilityCompanyForEnfinTpo));
  }, [GSFormValues, market]);

  useEffect(() => {
    console.log({ utilityCompaniesForEnfinTpo, GSFormValues });
  }, [utilityCompaniesForEnfinTpo, GSFormValues]);

  return (
    <div className="flex-column flex-ai-center width35 flex-jc-center">
      <ThemeProvider theme={theme}>
        {!authenticated ? <AuthenticationModal authenticate={authenticate} authenticated={authenticated} errorMessage={errorMessage} /> : null}
        {/* <div id="background-image">
          <div id="vivint-energy-img"></div>
          {authenticated ?
            <LogoutIcon
            className="absolute top-25 right-25 color-white cursor-pointer"
            onClick = {() => logout()}
            /> : null}
        </div> */}

        <div id="calculator" className="width100 flex-column flex-ai-fs flex-jc-fs">
          <div className="width100" id="logo_part">
            <img id="logo_image" src={LogoImage} alt="logo image" />
          </div>

          <div className="width100" id="my_dollar" style={{ textAlign: 'center', position: 'relative' }}>
            <p className="dollar-label">
              My <span className="dollar">$</span> = ?
            </p>
            <div className="dollar-box width80"></div>
            <p className="display-letter">
              Complete the form below to
              <br />
              calculate your potential pay.
            </p>
            <img id="scroll_image" src={ScrollImage} alt="scroll image" />
          </div>
          <div className="flex-row flex-jc-sb width100">
            <div style={{ flex: 1 }}>
              <p>
                Payscale<span className="select-one">(select one)</span>
              </p>
              <div className="flex-row width100 pp-flex-row flex-ai-sb flex-jc-sb" style={{ gap: '16px' }}>
                <Button className="" variant={payscale === 'On Trip' ? 'contained' : 'outlined'} onClick={() => handlePayscaleChange('On Trip')}>
                  On Trip
                </Button>
                <Button className="" variant={payscale === 'Off Trip/Local' ? 'contained' : 'outlined'} onClick={() => handlePayscaleChange('Off Trip/Local')}>
                  Off Trip/Local
                </Button>
                <Button className="" variant={payscale === 'Retail' ? 'contained' : 'outlined'} onClick={() => handlePayscaleChange('Retail')}>
                  Retail
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-row flex-jc-sb width100">
            <div className="width45">
              <RepTenure
                handleRepTenureValues={handleRepTenureValues}
                setRepTenureValues={setRepTenureValues}
                repTenureValues={repTenureValues}
                repTenure={repTenure}
                handleRepTenure={handleRepTenure}
                userType={cookies.userType}
                dealer={cookies.dealer}
                contractSignDate={contractSignDate}
                payscale={payscale}
                fetchRepTenureVariables={fetchRepTenureVariables}
                market={market}
              />
            </div>
            <div className="width45">
              <LeaderShipLevel
                leadershipLevel={leadershipLevel}
                handleLeadershipLevel={handleLeadershipLevel}
                userType={cookies.userType}
                setLeadershipLevel={setLeadershipLevel}
                dealer={cookies.dealer}
                repTenure={repTenure}
                contractSignDate={contractSignDate}
                fetchLeadershipOptions={fetchLeadershipOptions}
              />
            </div>
          </div>

          <FormGroup>
            {payscale === 'Off Trip/Local' && (
              <FormControlLabel
                disabled={uncommonDisabled}
                control={<Checkbox checked={uncommon} onChange={onChangeUncommon} />}
                label="Uncommon"
                className="uncommon"
              />
            )}
          </FormGroup>

          <div className="flex-row flex-jc-sb width100">
            <div className="flex-column width45">
              <p>Market</p>
              <div className="flex-row width100 pp-flex-row flex-ai-sb flex-jc-sb">
                <Box sx={{ minWidth: '100%' }}>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={market}
                      onChange={(e) => handleMarket(e.target.value, EPCValues, product, GSFormValues.marketValues, payscale)}
                      className="width100"
                    >
                      {loanAndCashMarketValues
                        ?.filter((x) => {
                          if (payscale === 'On Trip') {
                            return x.bucket === 'X';
                          } else if (payscale === 'Off Trip/Local') {
                            return x.bucket === 'Y';
                          } else if (payscale === 'Retail') {
                            return x.bucket === 'Retail';
                          }
                        })
                        .map((mv, i) => {
                          return (
                            <MenuItem key={mv + i} value={mv}>
                              {mv.market}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>

            {/* <div className='flex-column width45 flex-jc-sb'>
              <p>Contract Sign Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  value={contractSignDate}
                  onChange={(e) => {
                    handleContractSignDate(`${e.$M + 1}/${e.$D}/${e.$y}`);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  style={{width: '100%'}}
                />
              </LocalizationProvider>
            </div> */}
            {/* 
            {payscale !== 'Retail' && (
              <div className="flex-column width45">
                <p>
                  Freedom Lead?<span className="select-one">(select one)</span>
                </p>
                <div className="flex-row width100 pp-flex-row flex-ai-sb flex-jc-sb">
                  <Button
                    className=""
                    variant={apptFee == 'vivint' ? 'contained' : 'outlined'}
                    onClick={() => handleApptFee('vivint')}
                    sx={{ marginRight: '8px' }}
                  >
                    Yes
                  </Button>
                  <Button className='' variant={apptFee == 'nonVivint' ? "contained" : "outlined"} onClick={() => handleApptFee('nonVivint')}>No Vivint</Button>
                  <Button className="" variant={apptFee == 'noAppt' ? 'contained' : 'outlined'} onClick={() => handleApptFee('noAppt')}>
                    No
                  </Button>
                </div>
              </div>
            )} */}
          </div>
          <div className="flex-row flex-jc-sb width100">
            <div style={{ flex: 1 }}>
              <Tooltip title="This is the type of contract you are selling to the customer.">
                <p>
                  Product<span className="select-one">(select one)</span>
                </p>
              </Tooltip>
              <div className="flex-row pp-flex-row width100 flex-jc-sb" style={{ gap: '16px' }}>
                <Button className="width30" variant={product === 'Loan and Cash' ? 'contained' : 'outlined'} onClick={() => handleProduct('Loan and Cash')}>
                  Loan & Cash
                </Button>
                {market == '' || market.ppaBaseline ? (
                  <Button
                    className="width30 color-white"
                    variant={product === 'Sunrun TPO' ? 'contained' : 'outlined'}
                    onClick={() => handleProduct('Sunrun TPO')}
                  >
                    Sunrun TPO
                  </Button>
                ) : null}
                {!!market.enfinTpoBaseline ? (
                  <Button
                    className="width30 color-white"
                    variant={product === 'Enfin TPO' ? 'contained' : 'outlined'}
                    onClick={() => handleProduct('Enfin TPO')}
                  >
                    Enfin TPO
                  </Button>
                ) : null}
                {!!market.everbrightTpoBaseline ? (
                  <Button
                    className="width30 color-white"
                    variant={product === 'Everbright TPO' ? 'contained' : 'outlined'}
                    onClick={() => handleProduct('Everbright TPO')}
                  >
                    Everbright TPO
                  </Button>
                ) : null}
                {!!market.sunnovaTpoBaseline ? (
                  <Button
                    className="width30 color-white"
                    variant={product === 'Sunnova TPO' ? 'contained' : 'outlined'}
                    onClick={() => handleProduct('Sunnova TPO')}
                  >
                    Sunnova TPO
                  </Button>
                ) : null}
                {!!market.goodleapTpoBaseline ? (
                  <Button
                    className="width30 color-white"
                    variant={product === 'Goodleap TPO' ? 'contained' : 'outlined'}
                    onClick={() => handleProduct('Goodleap TPO')}
                  >
                    Goodleap TPO
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          {utilityCompaniesForEnfinTpo.length > 0 && product === 'Enfin TPO' && (
            <div className="flex-column width100">
              <p>Utility Company for Enfin TPO</p>
              <div className="flex-row width100 pp-flex-row flex-ai-sb flex-jc-sb">
                <Box sx={{ minWidth: '100%' }}>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={market.utilityCompanyForEnfinTpo}
                      onChange={(e) =>
                        setMarket(
                          GSFormValues.find((x) => x.utilityCompanyForEnfinTpo === e.target.value && x.bucket === market.bucket && x.market === market.market)
                        )
                      }
                      className="width100"
                    >
                      {utilityCompaniesForEnfinTpo.map((uc) => {
                        return (
                          <MenuItem key={uc} value={uc}>
                            {uc}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          )}

          <LoanAndCash
            installer={installer}
            handleInstaller={handleInstaller}
            GSFormValues={GSFormValues}
            netPricePerWatt={netPricePerWatt}
            handleNetPricePerWatt={handleNetPricePerWatt}
            systemSizeKW={systemSizeKW}
            handleSystemSizeKW={handleSystemSizeKW}
            handleLead={handleLead}
            handleDeductions={handleDeductions}
            lead={lead}
            deductions={deductions}
            market={market}
            repTenureValues={repTenureValues}
            repTenure={repTenure}
            product={product}
            contractSignDate={contractSignDate}
            setContractSignDate={handleContractSignDate}
            installerBaseline={installerBaseline}
            netPricePerWattValues={netPricePerWattValues}
            tcrVariables={tcrVariables}
            buildTablePaymentCalculations={buildTablePaymentCalculations}
            paymentCalculationsToDisplay={paymentCalculationsToDisplay}
            handleEstimatedInstalls={handleEstimatedInstalls}
            estimatedInstalls={estimatedInstalls}
            selectedPayScale={selectedPayScale}
            installerValues={installerValues}
            payscale={payscale}
            handlePayscaleChange={handlePayscaleChange}
            repTenurePercentages={repTenurePercentages}
            jrecMinimumCommissionPerKW={jrecMinimumCommissionPerKW}
            apptFee={apptFee}
            handleApptFee={handleApptFee}
            handleConciergeFee={handleConciergeFee}
            conciergeFee={conciergeFee}
            decimals={decimals}
          />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
