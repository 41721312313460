import React from 'react'
const AMOUNT_PER_KW = 100;

function truncateDecimals(number, digits) {
  var multiplier = Math.pow(10, digits),
      adjustedNum = number * multiplier,
      truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return parseFloat((truncatedNum / multiplier).toFixed(digits));
};

export default function CommissionBreakout(props) {
  const { paymentCalculationsToDisplay, selectedPayScale, systemSizeKW, repTenure } = props

  if (paymentCalculationsToDisplay.length === 0) {
    return null;
  }
  let m1 = 0;
  if (repTenure.id === 'Set' || repTenure.id === 'Self gen') {
    m1 = (AMOUNT_PER_KW * systemSizeKW > 1000) ? 1000 : AMOUNT_PER_KW * systemSizeKW;
  }
  const m2 = selectedPayScale.totalCommission * 0.8 - m1;
  const m3 = selectedPayScale.totalCommission * 0.2

  return(
    <div className='commission-breakout flex-column'>
      <div className='flex-row flex-jc-sb'>
        <p>Total</p>
        <p>${truncateDecimals(Number(selectedPayScale?.totalCommission), 2)}</p>
      </div>
      <p className='payment-timing'>Payment Timing</p>
      <div className='commission-table flex-column'>
        <div className='flex-row flex-jc-sb'>
          <p>M1 (Up Front)</p>
          <p>${truncateDecimals(Number(m1), 2)}</p>
        </div>
        <div className='flex-row flex-jc-sb'>
          <p>M2 (Install 80%)</p>
          <p>${truncateDecimals(Number(m2), 2)}</p>
        </div>
        <div className='flex-row flex-jc-sb'>
          <p>M3 (PTO 20%)</p>
          <p>${truncateDecimals(Number(m3), 2)}</p>
        </div>
      </div>
    </div>
  )
}